import { api } from "@/api"

export async function getPageGsc(libraryId, pageId) {
	const res = await api.get(`api/libraries/${libraryId}/pages/${ pageId }/gsc`)

	if (res?.data?.rows?.length > 0) {
		return res.data.rows.sort((a,b) => {
			return a.impressions > b.impressions ? -1 : 1
		}).sort((a,b) => {
			return a.clicks > b.clicks ? -1 : 1
		})
	} else if (res) {
		return []
	} else {
		return
	}
}
