import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import Cookies from "js-cookie";
import axios from "axios";
import { getSession, deleteSession } from "@/api/sessions";
import { listWorkspaceLibraries } from "@/api/workspace";
import { getRegisterUrl, getGaProperties, getGscProperties } from "@/api/user";
export const useUserStore = defineStore("user", () => {
  const router = useRouter();
  const libraryStore = useLibraryStore();
  const notificationStore = useNotificationStore();
  const userInit = {
    user: null,
    gaProperties: null,
    gscProperties: null,
    workspaces: null,
    fallbackLibrary: null,
    libraries: null
  };
  const isAdmin = computed(() => state.user?.email?.includes("@ercule.co"));
  const state = reactive({
    ...userInit
  });
  function resetUser() {
    Object.assign(state, userInit);
  }
  async function authCheck() {
    const res = await getSession();
    if (res) {
      state.user = res;
    } else {
      resetUser();
    }
  }
  async function loginWithGoogle() {
    const res = await getRegisterUrl();
    if (res) window.location = res;
  }
  async function logout() {
    const res = await deleteSession();
    if (res) {
      Cookies.remove("csrftoken");
      klaviyoCall("App Logout");
      libraryStore.resetLibrary();
      resetUser();
      router.push({
        name: "AppLogin"
      });
    } else {
      notificationStore.setError("Unable to log out fully, try reloading the page.");
    }
  }
  async function populateGaProperties() {
    state.gaProperties = await getGaProperties();
  }
  function hasNoLibraries() {
    return state.user && state.libraries?.length == 0;
  }
  async function populateGscProperties() {
    state.gscProperties = await getGscProperties();
  }
  async function getWorkspaceLibraries() {
    if (!state.user?.workspaces?.length > 0) return;
    const workspaceWithLibraries = state.user?.workspaces?.map(async ws => {
      ws.libraries = await listWorkspaceLibraries(ws.id);
      return ws;
    });
    state.workspaces = await Promise.all(workspaceWithLibraries);
    const hasALibrary = state.workspaces.find(ws => ws.libraries?.length > 0);
    state.fallbackLibrary = hasALibrary ? hasALibrary.libraries[0] : undefined;
  }
  async function klaviyoCall(eventName, eventProps = {}) {
    console.log("Event: " + eventName);
    if (process.env.NODE_ENV == "development" || !state.user) return;
    const klaviyoObject = {
      token: "Y6tuNE",
      event: eventName,
      customer_properties: {
        $email: state.user.email,
        $first_name: state.user.first_name || "",
        $last_name: state.user.last_name || "",
        "App User": true
      },
      properties: eventProps
    };
    const encodedParams = new URLSearchParams();
    encodedParams.set("data", JSON.stringify(klaviyoObject));
    const klaviyoOptions = {
      method: "POST",
      url: "https://a.klaviyo.com/api/track",
      headers: {
        Accept: "text/html",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: encodedParams
    };
    axios.request(klaviyoOptions).catch(err => console.error(err));
  }
  return {
    ...toRefs(state),
    isAdmin,
    resetUser,
    authCheck,
    loginWithGoogle,
    logout,
    populateGaProperties,
    hasNoLibraries,
    populateGscProperties,
    getWorkspaceLibraries,
    klaviyoCall
  };
});