import dayjs from "dayjs"

export function buildDatesLegacy() {
	let today = new Date()
	let firstDayOfYear = new Date(today.getFullYear(), today.getMonth() - 12, 1)
	let lastDayOfyear = new Date(today.getFullYear(), today.getMonth(), 1)
	lastDayOfyear.setDate(0)

	let dates = {
		today: dayjs(today).format("MMM D, YYYY"),
		allYear: {
			startDate: firstDayOfYear.toISOString().split("T")[0],
			endDate: lastDayOfyear.toISOString().split("T")[0],
		},
		raw: [],
		longDisplay: [],
		shortDisplay: [],
		allMonths: [],
		days: [],
	}

	for (var i = 0; i < 12; i++) {
		let s = 12 - i
		let first = new Date(today.getFullYear(), today.getMonth() - s, 1)
		let last = new Date(today.getFullYear(), today.getMonth() - s + 1, 1)
		last.setDate(0)
		let mo = {
			startDate: first.toISOString().split("T")[0],
			endDate: last.toISOString().split("T")[0],
		}
		dates.allMonths.push(mo)
		dates.raw.push(first)
		let displayMonth = dayjs(first)
		dates.shortDisplay.push(displayMonth.format("MMM YY"))
		dates.longDisplay.push(displayMonth.format("MMMM YYYY"))
	}

	return dates
}