import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'CellPageBase',
  props: {
    item: Object,
    target: {
      type: String,
      default: "_self"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CellBase = _resolveComponent("CellBase");
      return _openBlock(), _createBlock(_component_CellBase, {
        link: "PageSingle",
        target: __props.target,
        item: __props.item
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["target", "item"]);
    };
  }
};