import { useModel as _useModel } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "text-gray-500"
};
export default {
  __name: 'TableToggle',
  props: {
    "modelValue": {
      type: Boolean
    },
    "modelModifiers": {}
  },
  emits: ["update:modelValue"],
  setup(__props) {
    const checked = _useModel(__props, "modelValue");
    return (_ctx, _cache) => {
      const _component_Switch = _resolveComponent("Switch");
      const _component_SwitchLabel = _resolveComponent("SwitchLabel");
      const _component_SwitchGroup = _resolveComponent("SwitchGroup");
      return _openBlock(), _createBlock(_component_SwitchGroup, {
        as: "div",
        class: "flex items-center"
      }, {
        default: _withCtx(() => [_createVNode(_component_Switch, {
          modelValue: checked.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => checked.value = $event),
          class: _normalizeClass(["relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none", [checked.value ? 'bg-indigo-600' : 'bg-gray-200']])
        }, {
          default: _withCtx(() => [_createElementVNode("span", {
            "aria-hidden": "true",
            class: _normalizeClass(["pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out flex-none", [checked.value ? 'translate-x-5' : 'translate-x-0']])
          }, null, 2)]),
          _: 1
        }, 8, ["modelValue", "class"]), _createVNode(_component_SwitchLabel, {
          as: "span",
          class: "ml-3 text-sm select-none"
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])]),
          _: 3
        })]),
        _: 3
      });
    };
  }
};