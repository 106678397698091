import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { useRouter } from 'vue-router';
import { ref } from 'vue';
const _hoisted_1 = {
  class: "flex justify-end items-center mt-auto"
};
export default {
  __name: 'OnboardingFooter',
  props: {
    previousTo: {
      type: Object,
      required: true
    },
    nextTo: {
      type: Object,
      required: true
    },
    nextEnabled: Boolean,
    beforeNext: {
      type: Function,
      default: () => true
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const router = useRouter();
    const props = __props;
    const doingNext = ref(false);
    async function nextClick() {
      if (!props.nextEnabled) return;
      doingNext.value = true;
      const res = await props.beforeNext();
      if (res) {
        router.push(props.nextTo);
      }
      doingNext.value = false;
    }
    __expose({
      nextClick
    });
    return (_ctx, _cache) => {
      const _component_ButtonDefault = _resolveComponent("ButtonDefault");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ButtonDefault, {
        styles: "back",
        to: __props.previousTo
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "back", {}, () => [_createTextVNode("Back")])]),
        _: 3
      }, 8, ["to"]), _createVNode(_component_ButtonDefault, {
        enabled: __props.nextEnabled && !_unref(doingNext),
        styles: "primary",
        class: "ml-auto",
        onClick: nextClick
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "next", {}, () => [_createTextVNode("Next")])]),
        _: 3
      }, 8, ["enabled"])]);
    };
  }
};