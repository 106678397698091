import axios from "axios"
import { Notifier } from "@airbrake/browser"

export const baseURL = `${process.env.VUE_APP_BACKEND_URL}`
export const axiosOptions = { 
	baseURL,
	withCredentials: true,
	xsrfCookieName: "csrftoken",
	xsrfHeaderName: "X-CSRFTOKEN",
	headers: {"Content-Type": "application/json"}
}

export const api = axios.create(axiosOptions)
api.interceptors.response.use(res => res, defaultErrorHandling)

export const herokuApi = axios.create({ 
	baseURL: `${process.env.VUE_APP_HEROKU_BACKEND_URL}`
})
herokuApi.interceptors.response.use(res => res, defaultErrorHandling)

export function defaultErrorHandling(err) {

	if (process.env.NODE_ENV !== "development") {

		const airbrake = new Notifier({
			environment: process.env.NODE_ENV,
			projectId: 407346,
			projectKey: "a121e382d2caec2c1a37af2cd8b4e0f9",
		})

		airbrake.notify({
			error: err,
			params: err.config.params,
			requestUrl: err.config.url,
			requestMethod: err.config.method
		})
	}
}