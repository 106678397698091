import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "m-0 text-xs font-normal normal-case tracking-normal"
};
export default {
  __name: 'BaseTooltip',
  props: {
    size: {
      type: String,
      default: "default"
    },
    color: {
      type: String,
      default: "light"
    }
  },
  setup(__props) {
    const props = __props;
    const colorMap = {
      dark: "bg-zinc-800 text-zinc-50 border-zinc-700",
      light: "bg-white text-zinc-900 border-zinc-200"
    };
    const sizeMap = {
      auto: "whitespace-nowrap",
      default: "w-64"
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tooltip-content invisible opacity-0 group-hover:visible group-hover:opacity-100 absolute group-hover:delay-300 p-3 transition-all duration-100 delay-75", [__props.size, sizeMap[props.size]]]),
        style: {
          "z-index": "999999"
        }
      }, [_createElementVNode("div", {
        class: _normalizeClass(["px-3 py-2 shadow-lg rounded-lg pointer-events-auto border overflow-hidden", [colorMap[props.color]]])
      }, [_createElementVNode("p", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 2)], 2);
    };
  }
};