import escapeStringRegexp from "escape-string-regexp"

export function getTopicRegexObject(input, type = "string") {
	if (type == "string") {
		return {
			string: input,
			terms: topicStringToTerms(input), 
			regex: topicStringToRegex(input)
		}
	} else if (type == "regex") {
		return {
			string: topicRegexToTerms(input).join(", "),
			terms: topicRegexToTerms(input),
			regex: input
		}
	} else if (type == "terms") {
		return {
			string: input.join(", "),
			terms: input,
			regex: topicStringToRegex(input.join(", "))
		}
	}
}

function topicStringToTerms(string) {
	const terms = string.toLowerCase().trim().split(",")
	return terms.map(term => term.trim()).filter(term => term != "")
}

function topicStringToRegex(string) {
	let reg = ""
	const terms = string.toLowerCase().trim().split(",")
	terms.forEach(term=>{
		if (term.trim() == "") return
		term = escapeStringRegexp(term)
		reg += ".*"
		const words = term.trim().split(" ")
		words.forEach(word => {
			reg += word.trim() + ".*"
		})
		reg += "|"
	})
	return reg.slice(0, -1)
}

function topicRegexToTerms(regex) {
	return regex.trim().replaceAll(".*", " ").split("|").map(term => term.trim())
}