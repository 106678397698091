import { useModel as _useModel } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";

import { computed } from 'vue';
const _hoisted_1 = {
  class: "flex items-center justify-between select-none rounded-b-md",
  "aria-label": "Pagination"
};
const _hoisted_2 = {
  class: "hidden sm:block"
};
const _hoisted_3 = {
  key: 0,
  class: "text-gray-700"
};
const _hoisted_4 = {
  class: "font-medium"
};
const _hoisted_5 = {
  class: "font-medium"
};
const _hoisted_6 = {
  class: "font-medium"
};
const _hoisted_7 = {
  class: "flex-1 flex justify-between sm:justify-end"
};
export default {
  __name: 'TablePagination',
  props: {
    "conf": {
      type: Object
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const conf = _useModel(__props, "conf");
    const firstItemIndex = computed(() => (conf.value.pageNumber - 1) * conf.value.pageSize);
    function paginate(num) {
      conf.value.setPage(conf.value.pageNumber + num);
    }
    return (_ctx, _cache) => {
      const _component_ButtonDefault = _resolveComponent("ButtonDefault");
      return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createElementVNode("div", _hoisted_2, [conf.value.totalItems ? (_openBlock(), _createElementBlock("p", _hoisted_3, [_createTextVNode(" Showing "), _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(firstItemIndex) + 1), 1), _createTextVNode(" to "), _createElementVNode("span", _hoisted_5, _toDisplayString(Math.min(_unref(firstItemIndex) + conf.value.pageSize, conf.value.totalItems)), 1), _createTextVNode(" of "), _createElementVNode("span", _hoisted_6, _toDisplayString(conf.value.totalItems), 1), _createTextVNode(" results ")])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_ButtonDefault, {
        enabled: conf.value.pageNumber != 1 && conf.value.totalItems > conf.value.pageSize,
        onClick: _cache[0] || (_cache[0] = $event => paginate(-1))
      }, {
        default: _withCtx(() => [_createTextVNode(" Previous ")]),
        _: 1
      }, 8, ["enabled"]), _createVNode(_component_ButtonDefault, {
        class: "ml-3",
        enabled: _unref(firstItemIndex) + conf.value.pageSize < conf.value.totalItems && conf.value.totalItems > conf.value.pageSize,
        onClick: _cache[1] || (_cache[1] = $event => paginate(1))
      }, {
        default: _withCtx(() => [_createTextVNode(" Next ")]),
        _: 1
      }, 8, ["enabled"])])]);
    };
  }
};