export function urlToCanonicalPageId(url) {
	if (typeof url !== "string") return ""

	let urlString = url.replace("https://", "").replace("http://", "")

	if (urlString.slice(-1) === "/") {
		urlString = urlString.slice(0, -1)
	}

	return urlString
}