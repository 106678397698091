import { postLibrary, postLibraryPublish, getLibraryStatus } from "@/api/library";
import { useVuelidate } from "@vuelidate/core";
import { minLength, maxLength, required, requiredIf, helpers } from "@vuelidate/validators";
export const useLibraryFormStore = defineStore("libraryForm", () => {
  const userStore = useUserStore();
  const notificationStore = useNotificationStore();
  let libraryCheckInterval;
  const estimationMultiplier = 1.5;
  const waitingForPreview = ref(false);
  const libraryPreviewResponse = ref(null);
  const filteredLibraryPreviewResponse = ref(null);
  const formInit = {
    libraryName: null,
    accountSelect: null,
    webPropSelect: null,
    gscPropertySelect: null,
    pathFilterType: null,
    pathFilterValues: [],
    hostnameFilterType: null,
    hostnameFilterValues: []
  };
  const form = reactive({
    ...formInit
  });
  const rules = {
    libraryName: {
      required: helpers.withMessage("A library name is required.", required),
      minLength: helpers.withMessage("Library name should have at least 3 characters.", minLength(3)),
      maxLength: helpers.withMessage("Library name should be less than 100 characters.", maxLength(100))
    },
    accountSelect: {
      required: helpers.withMessage("A Google Analytics account is required to create a library.", required)
    },
    gscPropertySelect: {},
    webPropSelect: {
      required: helpers.withMessage("A web property is required to create a library.", required)
    }
  };
  watch(() => form.webPropSelect, () => {
    resetFilters();
  });
  const v$ = useVuelidate(rules, form, {
    $lazy: true
  });
  const id = computed(() => {
    return filteredLibraryPreviewResponse.value ? filteredLibraryPreviewResponse.value.id : null;
  });
  function resetForm() {
    clearLibraryCheckInterval();
    Object.assign(form, formInit);
    v$.value.$reset();
    libraryPreviewResponse.value = null;
    filteredLibraryPreviewResponse.value = null;
  }
  function resetFilters() {
    clearLibraryCheckInterval();
    form.pathFilterType = null;
    form.hostnameFilterType = null;
    form.pathFilterValues = [];
    form.hostnameFilterValues = [];
    v$.value.$reset();
    libraryPreviewResponse.value = null;
    filteredLibraryPreviewResponse.value = null;
  }
  const hasNoSpaces = value => !helpers.req(value) || !(value.trim().indexOf(" ") >= 0);
  const gaDisplayName = computed(() => {
    return form.webPropSelect ? form.webPropSelect.name : undefined;
  });
  const gscDisplayName = computed(() => {
    return form.gscPropertySelect ? form.gscPropertySelect.site_url?.replace("sc-domain:", "Domain: ") : undefined;
  });
  const hostnameOptions = computed(() => {
    if (libraryPreviewResponse.value?.onboarding_metadata?.hostnames) {
      const hostnames = libraryPreviewResponse.value?.onboarding_metadata?.hostnames;
      return Object.keys(hostnames).map(hostname => {
        const paths = hostnames[hostname];
        const sum = Object.values(paths).reduce((sum, current) => sum + current, 0);
        const adjustedSum = numDisplay(round(sum * estimationMultiplier), "m");
        const subtitle = adjustedSum <= 5 ? "(<5 pages)" : `(~${adjustedSum} pages)`;
        return {
          title: hostname,
          subtitle,
          count: sum
        };
      });
    } else {
      return [];
    }
  });
  const pathOptions = computed(() => {
    if (libraryPreviewResponse.value?.onboarding_metadata?.hostnames) {
      const pathMap = {};
      const hostnameArray = Object.keys(libraryPreviewResponse.value?.onboarding_metadata?.hostnames).map(hostname => {
        return {
          hostname,
          paths: libraryPreviewResponse.value.onboarding_metadata.hostnames[hostname]
        };
      });
      hostnameArray.forEach(host => {
        Object.keys(host.paths).forEach(path => {
          pathMap[path] = pathMap[path] ? pathMap[path] + host.paths[path] : host.paths[path] || 0;
        });
      });
      return Object.keys(pathMap).map(path => {
        const sum = pathMap[path];
        const adjustedSum = numDisplay(round(sum * estimationMultiplier), "m");
        const subtitle = adjustedSum <= 5 ? "(<5 pages)" : `(~${adjustedSum} pages)`;
        return {
          title: path,
          subtitle,
          count: sum
        };
      });
    } else {
      return [];
    }
  });
  const librarySizeEstimate = computed(() => {
    const est = libraryPreviewResponse.value?.onboarding_metadata?.page_count_estimation;
    let displayEstimate = 0;
    if (form.pathFilterValues?.length > 0 && est) {
      const pathSum = form.pathFilterValues.reduce((sum, current) => sum + current.count, 0);
      displayEstimate = form.pathFilterType === "INCLUDE" ? pathSum : est - pathSum;
    } else if (form.hostnameFilterValues?.length > 0) {
      const hostSum = form.hostnameFilterValues.reduce((sum, current) => sum + current.count, 0);
      displayEstimate = form.hostnameFilterType === "INCLUDE" ? hostSum : est - hostSum;
    } else if (est) {
      displayEstimate = est;
    }
    return round(displayEstimate * estimationMultiplier);
  });
  const properties = computed(() => {
    if (!form.accountSelect) return;
    return form.accountSelect.properties;
  });
  watch([() => form.accountSelect], () => {
    form.webPropSelect = null;
  });
  function getLibraryBody(withFilters = false) {
    const body = {
      name: form.libraryName,
      account_id: form.accountSelect.id,
      ga4_property_id: form.webPropSelect.id
    };
    if (form.gscPropertySelect) {
      body.gsc_siteurl = form.gscPropertySelect.site_url;
    }
    if (form.hostnameFilterType && withFilters) {
      body.hostname_filter_type = form.hostnameFilterType;
      body.hostname_filter_values = multiselectFormat(form.hostnameFilterValues);
    }
    if (form.pathFilterType && withFilters) {
      body.path_filter_type = form.pathFilterType;
      body.path_filter_values = multiselectFormat(form.pathFilterValues);
    }
    return body;
  }
  async function createLibrary(workspaceId, withFilters = false) {
    const isValid = await v$.value.$validate();
    if (!isValid) return;
    if (withFilters) {
      filteredLibraryPreviewResponse.value = null;
    } else {
      libraryPreviewResponse.value = null;
    }
    const res = await postLibrary(workspaceId, getLibraryBody(withFilters));
    if (res) {
      setLibraryCheckInterval(workspaceId, res.id, withFilters);
      return true;
    } else {
      return false;
    }
  }
  function multiselectFormat(array) {
    if (array && array.length > 0) {
      return array.map(item => item.title);
    } else {
      return [];
    }
  }
  function setLibraryCheckInterval(workspaceId, libraryId, withFilters = false) {
    clearLibraryCheckInterval();
    waitingForPreview.value = true;
    libraryCheckInterval = setInterval(libraryCheck, 2500, workspaceId, libraryId, withFilters);
  }
  function clearLibraryCheckInterval() {
    clearInterval(libraryCheckInterval);
    waitingForPreview.value = false;
  }
  async function libraryCheck(workspaceId, libraryId, withFilters) {
    console.log("checking library " + libraryId);
    const res = await getLibraryStatus(workspaceId, libraryId);
    console.log(res);
    if (res && res.onboarding_status?.stage === "METADATA_PROCESS" && res.onboarding_status?.status === "COMPLETE") {
      if (withFilters) {
        filteredLibraryPreviewResponse.value = res;
      } else {
        libraryPreviewResponse.value = res;
      }
      clearLibraryCheckInterval();
    } else if (!res) {
      clearLibraryCheckInterval();
    }
  }
  async function publishLibrary(workspaceId, libraryId) {
    const res = await postLibraryPublish(workspaceId, libraryId);
    if (res) {
      userStore.klaviyoCall("Created Library", {
        library_id: res.id
      });
      await userStore.getWorkspaceLibraries();
      resetForm();
      return true;
    } else {
      notificationStore.setError("Library could not be created, try refreshing the page and starting over.");
      return false;
    }
  }
  return {
    ...toRefs(form),
    v$,
    id,
    resetForm,
    publishLibrary,
    createLibrary,
    libraryPreviewResponse,
    filteredLibraryPreviewResponse,
    properties,
    hostnameOptions,
    pathOptions,
    librarySizeEstimate,
    waitingForPreview,
    gaDisplayName,
    gscDisplayName
  };
});