import { reactive } from "vue"
import { defineStore } from "pinia"

export const useNotificationStore = defineStore("notification", ()=>{
	const notifications = reactive([])
	const maxNotifications = 2

	function setError(message) {
		setNotification("Something went wrong", message, "red")
	}

	function setNotification(title, message, color = "gray", button = undefined) {
		let newNotification = {title, message, color, button}
		const existingIndex = checkNotificationExistence(newNotification)
		if (existingIndex !== undefined) {
			newNotification = notifications.splice(existingIndex, 1)[0]
		}
		notifications.push(newNotification)
		cleanNotifications()
	}

	function cleanNotifications() {
		if (notifications.length > maxNotifications) {
			notifications.shift()
		}
	}

	function clearNotification(index) {
		notifications.splice(index, 1)
	}

	function checkNotificationExistence(notif) {
		let matchIndex = undefined
		notifications.forEach((notification, index) => {
			if (
				notif.title == notification.title &&
        notif.message == notification.message &&
        notif.color == notification.color
			) {
				matchIndex = index
			}
		})
		return matchIndex
	}

	return { notifications, setError, setNotification, clearNotification }
})
