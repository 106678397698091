import dayjs from "dayjs"
import { api } from "@/api"
import { TimeSeries } from "@/utils"

export async function getLibraryAggregate(libraryId, syncDate) {
	const res = await api.get(`/api/libraries/${libraryId}/aggregate`, {
		params: { 
			date_range_start: dayjs().subtract(3, "years").subtract(3, "days").format("YYYYMMDD"),
			date_range_end: dayjs().subtract(3, "days").format("YYYYMMDD")
		}
	})

	if (res?.data?.all_aggregate_datas?.length > 0) {
		const all = new TimeSeries(res.data.all_aggregate_datas, "aggregate", syncDate)
		const org = new TimeSeries(res.data.org_aggregate_datas, "aggregate", syncDate)
		return { all, org }
	} else {
		return
	}
}