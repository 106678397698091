// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp, markRaw } from "vue"
import App from "./App"
import router from "./routes"
import { Notifier } from "@airbrake/browser"
import { createPinia } from "pinia"
import "@/assets/css/tailwind.css"

const app = createApp(App)
const pinia = createPinia()
pinia.use(({ store }) => {
	// so we have access to router object in stores
	store.router = markRaw(router)
})
app.use(pinia)
app.use(router)

const airbrake = new Notifier({
	environment: process.env.NODE_ENV,
	projectId: 407346,
	projectKey: "a121e382d2caec2c1a37af2cd8b4e0f9",
})

app.config.errorHandler = function (err, _vm, info) {
	console.error(err)
	if (process.env.NODE_ENV !== "development") {
		airbrake.notify({
			error: err,
			params: { info: info },
		})
	}
}

app.mount("#app")
