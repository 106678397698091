import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1,
  class: "text-slate-300"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1,
  class: "text-slate-300"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1,
  class: "text-slate-300"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1,
  class: "text-slate-300"
};
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  key: 1
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  key: 2,
  class: "text-slate-300"
};
const _hoisted_13 = {
  key: 0
};
const _hoisted_14 = {
  key: 0
};
const _hoisted_15 = {
  key: 1,
  class: "text-slate-500 animate-pulse"
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  key: 0
};
const _hoisted_18 = {
  key: 1
};
const _hoisted_19 = {
  key: 2,
  class: "text-slate-300"
};
import { numDisplay } from "@/utils";
import { getWorkspace } from "@/api/workspace";

import { useRoute } from 'vue-router';
import { useLibraryFormStore } from '@/stores';
import { ref, computed, onMounted } from 'vue';
export default {
  __name: 'OnboardingPanelLibraryDetails',
  setup(__props) {
    const route = useRoute();
    const libraryForm = useLibraryFormStore();
    const workspaceName = ref(null);
    const bubblesToShow = ref(5);
    const filterBubbles = computed(() => {
      let filters = [];
      const filterTypeMap = {
        hostname: {
          "EXCLUDE": "-",
          "INCLUDE": "+"
        },
        path: {
          "EXCLUDE": "-",
          "INCLUDE": "+"
        }
      };
      libraryForm.hostnameFilterValues.forEach(filter => {
        filters.push({
          text: filterTypeMap.hostname[libraryForm.hostnameFilterType] + " " + filter.title
        });
      });
      libraryForm.pathFilterValues.forEach(filter => {
        filters.push({
          text: filterTypeMap.path[libraryForm.pathFilterType] + " " + filter.title
        });
      });
      if (filters.length - bubblesToShow.value > 0) {
        const count = filters.length - bubblesToShow.value;
        filters = filters.slice(0, bubblesToShow.value);
        filters.push({
          text: `And ${count} more...`
        });
      }
      return filters;
    });
    const filterBubblesSecondary = computed(() => {
      let filters = [];
      if (!libraryForm.filteredLibraryPreviewResponse) return filters;
      const filterTypeMap = {
        hostname: {
          "EXCLUDE": "-",
          "INCLUDE": "+"
        },
        path: {
          "EXCLUDE": "-",
          "INCLUDE": "+"
        }
      };
      libraryForm.filteredLibraryPreviewResponse.hostname_filter_values.forEach(filter => {
        filters.push({
          text: filterTypeMap.hostname[libraryForm.filteredLibraryPreviewResponse.hostname_filter_type] + " " + filter
        });
      });
      libraryForm.filteredLibraryPreviewResponse.path_filter_values.forEach(filter => {
        filters.push({
          text: filterTypeMap.path[libraryForm.filteredLibraryPreviewResponse.hostname_filter_type] + " " + filter
        });
      });
      if (filters.length - bubblesToShow.value > 0) {
        const count = filters.length - bubblesToShow.value;
        filters = filters.slice(0, bubblesToShow.value);
        filters.push({
          text: `And ${count} more...`
        });
      }
      return filters;
    });
    onMounted(() => {
      getWorkspaceName();
    });
    async function getWorkspaceName() {
      const res = await getWorkspace(route.params.workspaceId);
      workspaceName.value = res.name;
    }
    return (_ctx, _cache) => {
      const _component_BaseStackedListItem = _resolveComponent("BaseStackedListItem");
      const _component_BubbleGroup = _resolveComponent("BubbleGroup");
      const _component_BaseStackedList = _resolveComponent("BaseStackedList");
      return _openBlock(), _createBlock(_component_BaseStackedList, null, {
        title: _withCtx(() => [_createTextVNode(" Library Details ")]),
        default: _withCtx(() => [_createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Workspace ")]),
          default: _withCtx(() => [_unref(workspaceName) ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(workspaceName)), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_2, " Workspace name... "))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Library Name ")]),
          default: _withCtx(() => [_unref(libraryForm).libraryName ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(libraryForm).libraryName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_4, " Library name... "))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Analytics ")]),
          default: _withCtx(() => [_unref(libraryForm).gaDisplayName ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(libraryForm).gaDisplayName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_6, " Connected GA account... "))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Search Console ")]),
          default: _withCtx(() => [_unref(libraryForm).gscDisplayName ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(libraryForm).gscDisplayName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, " Connected GSC account... "))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Filters ")]),
          default: _withCtx(() => [_unref(route).name === 'WorkspaceCreateLibraryReview' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_unref(filterBubbles).length > 0 ? (_openBlock(), _createBlock(_component_BubbleGroup, {
            key: 0,
            class: "flex-wrap",
            size: "sm",
            items: _unref(filterBubbles)
          }, null, 8, ["items"])) : (_openBlock(), _createElementBlock("span", _hoisted_10, "(None)"))])) : _unref(filterBubbles).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_BubbleGroup, {
            class: "flex-wrap",
            size: "sm",
            items: _unref(filterBubbles)
          }, null, 8, ["items"])])) : (_openBlock(), _createElementBlock("div", _hoisted_12, " Library filters... "))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Size Estimate ")]),
          default: _withCtx(() => [_unref(route).name === 'WorkspaceCreateLibraryReview' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [!_unref(libraryForm).waitingForPreview && _unref(libraryForm).filteredLibraryPreviewResponse ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(numDisplay)(_unref(libraryForm).filteredLibraryPreviewResponse.page_count, 'md')) + " Pages", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_15, "Calculating..."))])) : _unref(libraryForm).librarySizeEstimate ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_unref(libraryForm).librarySizeEstimate <= 10 ? (_openBlock(), _createElementBlock("span", _hoisted_17, " < 10 Pages ")) : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(numDisplay)(_unref(libraryForm).librarySizeEstimate, 's')) + " Pages", 1))])) : (_openBlock(), _createElementBlock("div", _hoisted_19, " (Unknown) "))]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};