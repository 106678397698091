import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { useRoute } from 'vue-router';
import { useLibraryStore } from '@/stores';
import { watch } from 'vue';
const _hoisted_1 = {
  class: "h-full"
};
export default {
  __name: 'LibraryLayout',
  setup(__props) {
    const route = useRoute();
    const libraryStore = useLibraryStore();
    if (route.params.libraryId && route.params.workspaceId) {
      libraryStore.loadLibrary(parseInt(route.params.workspaceId), parseInt(route.params.libraryId));
    }
    watch(() => route.params.libraryId, newId => {
      libraryStore.loadLibrary(parseInt(route.params.workspaceId), parseInt(newId));
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};