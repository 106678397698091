import { api } from "@/api";
export async function listLibraryTopics(libraryId) {
  const res = await api.get(`/api/libraries/${libraryId}/topics/`);
  if (res?.data?.length > 0) {
    res.data.forEach(topic => {
      const topicObj = getTopicRegexObject(topic.regex, "regex");
      topic.string = topicObj.string;
      topic.terms = topicObj.terms;
    });
    return res.data;
  } else {
    return [];
  }
}