import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
import { numDisplay } from "@/utils";
export default {
  __name: 'CellNumber',
  props: {
    item: Object,
    data: [Number, String],
    link: String,
    target: {
      type: String,
      default: "_self"
    },
    format: {
      type: String,
      default: "m"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CellBase = _resolveComponent("CellBase");
      return _openBlock(), _createBlock(_component_CellBase, {
        item: __props.item,
        link: __props.link,
        target: __props.target
      }, {
        default: _withCtx(() => [typeof __props.data === 'number' ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(numDisplay)(__props.data, __props.format)), 1)) : typeof __props.data === 'string' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.data), 1)) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["item", "link", "target"]);
    };
  }
};