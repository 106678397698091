import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "truncate"
};
export default {
  __name: 'CellPageTitle',
  props: {
    item: Object,
    target: {
      type: String,
      default: "_self"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CellBase = _resolveComponent("CellBase");
      return _openBlock(), _createBlock(_component_CellBase, {
        link: "PageSingle",
        target: __props.target,
        item: __props.item,
        title: __props.item.meta?.title
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode(_toDisplayString(__props.item.meta?.title), 1)])])]),
        _: 3
      }, 8, ["target", "item", "title"]);
    };
  }
};