import { api } from "@/api"
import dayjs from "dayjs"

export async function getLibraryGscQueryAndPage(libraryId, options) {
	const body = {
		"startDate": options.startDate || dayjs().subtract(31, "day").format("YYYY-MM-DD"),
		"endDate": options.endDate || dayjs().subtract(1, "day").format("YYYY-MM-DD"),
		"rowLimit": options.rowLimit || 10,
		"dimensions": ["QUERY","PAGE"],
		"dimensionFilterGroups": [
			{
				"groupType": "AND",
				"filters": [
					{
						"dimension": options.regexDimension || "QUERY",
						"operator": options.regexType || "INCLUDING_REGEX",
						"expression": options.regex,
					},
				],
			},
		],
	}

	const res = await api.post(`api/libraries/${libraryId}/gsc`, body)

	if (res?.data?.rows?.length > 0) {

		const keywordLibrary = {}

		res.data.rows.forEach(row=> {
			const keyword = row.keys[0]
			const url = row.keys[1]
			const impressions = row.impressions
			const clicks = row.clicks
			const position = row.position
			const thisPage = { url, clicks, impressions, position }

			if (keywordLibrary[keyword]) {
				keywordLibrary[keyword].clicks += row.clicks
				keywordLibrary[keyword].impressions += row.impressions
				keywordLibrary[keyword].pages.push(thisPage)
			} else {
				keywordLibrary[keyword] = { keyword, clicks, impressions, pages: [thisPage] }
			}
		})

		return Object.keys(keywordLibrary).map(key => {
			const keyword = keywordLibrary[key]
			keyword.position = keyword.pages[0].position
			keyword.topPage = keyword.pages[0]
			console.log(keyword.pages)

			if (keyword.impressions > 10) {
				keyword.volume = Math.round(keyword.impressions * Math.cbrt((Math.min(keyword.position, 50) + 1) / 2))
			} else {
				keyword.volume = 10
			}
			keyword.volume = keyword.volume > 1000 ? Math.round(keyword.volume / 100) * 100 : Math.round(keyword.volume / 10) * 10
			return keyword
		})
			.sort((a,b)=> a.impressions > b.impressions ? -1 : 1)
			.sort((a,b)=> a.clicks > b.clicks ? -1 : 1)

	} else {
		return []
	}
}