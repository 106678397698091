import { api } from "@/api"
import { getTopicRegexObject } from "@/utils"

export async function getLibraryTopic(libraryId, topicSlug) {
	const res = await api.get(`/api/libraries/${libraryId}/topics/${topicSlug}`)
	if (res?.data) {
		const topicObj = getTopicRegexObject(res.data.regex, "regex")
		res.data.string = topicObj.string
		res.data.terms = topicObj.terms
		return res.data
	} else {
		return
	}
}
