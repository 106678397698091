import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
const _hoisted_1 = {
  key: 1
};
export default {
  __name: 'CellBaseLink',
  props: {
    item: {
      type: Object,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: "_self"
    }
  },
  setup(__props) {
    const props = __props;
    const params = computed(() => {
      const linkType = linkMap[props.link];
      if (!linkType || !props.item[linkType.itemField]) return;
      let identifier = props.item[linkType.itemField];
      identifier = linkType.paramType == Number ? parseInt(identifier) : identifier;
      return {
        [linkType.paramName]: identifier
      };
    });
    const linkMap = {
      "PageSingle": {
        itemField: "id",
        paramName: "pageId",
        paramType: Number
      }
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _unref(params) ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        target: __props.target,
        to: {
          name: __props.link,
          params: _unref(params)
        }
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["target", "to"])) : (_openBlock(), _createElementBlock("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]));
    };
  }
};