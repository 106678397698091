import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex justify-between"
};
const _hoisted_2 = {
  for: "library-name",
  class: "block text-sm font-medium leading-6 text-gray-900"
};
const _hoisted_3 = {
  key: 0,
  class: "text-sm leading-6 text-gray-400"
};
export default {
  __name: 'BaseInputLabel',
  props: {
    required: Boolean
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), __props.required ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Required")) : _createCommentVNode("", true)]);
    };
  }
};