import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
import MaintenanceLayout from "@/layouts/MaintenanceLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";

import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores';
import { onMounted, watch } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE == "TRUE";
    const route = useRoute();
    const userStore = useUserStore();
    onMounted(() => {
      if (window) {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(["setPath", route.path]);
        watch(() => route.path, () => {
          _hsq.push(["setPath", route.path]);
          _hsq.push(["trackPageView"]);
        });
        watch(() => userStore.user, newVal => {
          if (newVal?.email) {
            _hsq.push(["identify", {
              email: newVal.email
            }]);
          }
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return maintenanceMode ? (_openBlock(), _createBlock(MaintenanceLayout, {
        key: 0
      })) : (_openBlock(), _createBlock(AppLayout, {
        key: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }));
    };
  }
};