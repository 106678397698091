import { useModel as _useModel } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'TableSearch',
  props: {
    "conf": {
      type: Object
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const conf = _useModel(__props, "conf");
    async function doSearch(searchTerm) {
      conf.value.setSearch(searchTerm);
    }
    return (_ctx, _cache) => {
      const _component_SearchBase = _resolveComponent("SearchBase");
      return _openBlock(), _createBlock(_component_SearchBase, {
        button: false,
        class: "w-full flex-none",
        onSearch: doSearch
      });
    };
  }
};