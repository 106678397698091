import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { computed } from 'vue';
export default {
  __name: 'CellPageURL',
  props: {
    item: Object,
    target: {
      type: String,
      default: "_self"
    }
  },
  setup(__props) {
    const props = __props;
    const url = computed(() => props.item?.canonical_page_id);

    // const characters = computed(()=>{
    // 	if (!url.value) return

    // 	const charPieces = []
    // 	const chunks = url.value.split("/")
    // 	chunks.forEach((chunk,index) => {
    // 		if (index > 0) {
    // 			const dashBits = chunk.split("-")
    // 			dashBits.forEach(bit =>{
    // 				charPieces.push({ type: "text", data: bit })
    // 				charPieces.push({ type: "dash", data: "-" })
    // 			})
    // 			charPieces.pop()
    // 		} else {
    // 			charPieces.push({ type: "text", data: chunk })
    // 		}

    // 		charPieces.push({ type: "slash", data: "/" })
    // 	})
    // 	charPieces.pop()
    // 	return charPieces
    // })

    return (_ctx, _cache) => {
      const _component_CellBase = _resolveComponent("CellBase");
      return _openBlock(), _createBlock(_component_CellBase, {
        link: "PageSingle",
        target: __props.target,
        item: __props.item,
        title: __props.item.canonical_page_id
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_unref(url)), 1)]),
        _: 1
      }, 8, ["target", "item", "title"]);
    };
  }
};