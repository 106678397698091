import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "truncate block px-3 h-full flex items-center"
};
export default {
  __name: 'CellBase',
  props: {
    item: Object,
    link: String,
    target: {
      type: String,
      default: "_self"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CellBaseLink = _resolveComponent("CellBaseLink");
      return _openBlock(), _createElementBlock("td", null, [__props.link && __props.item ? (_openBlock(), _createBlock(_component_CellBaseLink, {
        key: 0,
        link: __props.link,
        target: __props.target,
        item: __props.item,
        class: "truncate block px-3 h-full flex items-center"
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["link", "target", "item"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]))]);
    };
  }
};