import { api } from "@/api"

export async function getGaProperties() {
	const res = await api.get("/api/me/ga-properties")
	if (res?.status >= 300) {
		return []
	} else if (res?.status < 300 && res?.data){
		return res.data
	} else {
		return []
	}
}