import { herokuApi } from "@/api"
import Papa from "papaparse"

export async function getBatchKeywordData(keywordArray) {
	const res = await herokuApi.post("/semrush-batch-keyword-overview/", {
		keywords: keywordArray
	})

	if (res?.data?.results) {
		const { data } = Papa.parse(res.data.results, { header: true, dynamicTyping: true })
		return data.map(row => {
			return {
				keyword: row["Keyword"],
				volume: row["Search Volume"],
				competition: row["Keyword Difficulty Index"]
			}
		})
	} else {
		return []
	}
}