import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 0
};
export default {
  __name: 'TableCheckbox',
  props: {
    active: Boolean
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        class: _normalizeClass(["checkbox flex-none flex justify-center items-center select-none", {
          'active': __props.active
        }])
      }, [__props.active ? (_openBlock(), _createElementBlock("div", _hoisted_1, " ✓ ")) : _createCommentVNode("", true)], 2);
    };
  }
};