import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "ml-1 mr-3"
};
export default {
  __name: 'AppSidebarItem',
  props: {
    link: undefined,
    emoji: undefined,
    icon: undefined,
    params: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createBlock(_component_router_link, {
        to: {
          name: __props.link,
          params: __props.params
        },
        class: "text-zinc-50 hover:bg-zinc-800 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      }, {
        default: _withCtx(() => [__props.icon ? (_openBlock(), _createBlock(_resolveDynamicComponent(__props.icon), {
          key: 0,
          class: "text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-5 w-5",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor",
          "aria-hidden": "true"
        })) : _createCommentVNode("", true), __props.emoji ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.emoji), 1)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["to"]);
    };
  }
};