import { api } from "@/api"
import { processPages, scorePages } from "@/utils"

export async function getPage(libraryId, pageId) {
	const res = await api.get(`/api/libraries/${libraryId}/pages/${pageId}`)
	if (res?.data) {
		const processedPage = processPages(res.data)
		return scorePages(processedPage)
	} else {
		return 
	}
}