import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, withKeys as _withKeys, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { useRoute } from 'vue-router';
import { useUserStore, useLibraryFormStore } from '@/stores';
import { ref } from 'vue';
const _hoisted_1 = {
  class: "mb-12"
};
export default {
  __name: 'WorkspaceCreateLibrary',
  setup(__props) {
    const route = useRoute();
    const userStore = useUserStore();
    const libraryForm = useLibraryFormStore();
    const v$ = libraryForm.v$;
    const onboardingFooter = ref(null);
    const previousTo = ref({
      name: "WorkspaceManageUsers",
      params: {
        workspaceId: route.params.workspaceId
      }
    });
    const nextTo = ref({
      name: "WorkspaceCreateLibraryAnalytics",
      params: {
        workspaceId: route.params.workspaceId
      }
    });
    return (_ctx, _cache) => {
      const _component_OnboardingHeader = _resolveComponent("OnboardingHeader");
      const _component_BaseInputLabel = _resolveComponent("BaseInputLabel");
      const _component_BaseInputText = _resolveComponent("BaseInputText");
      const _component_OnboardingFooter = _resolveComponent("OnboardingFooter");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_OnboardingHeader, {
        "current-num": "1",
        "total-num": "6"
      }, {
        title: _withCtx(() => [_createTextVNode(" Create a library ")]),
        description: _withCtx(() => [_createTextVNode(" Set up a content library for a group of pages (e.g. your blog). To get the most out of a library, we recommend keeping it under 1000 pages. ")]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BaseInputLabel, {
        for: "library-name"
      }, {
        default: _withCtx(() => [_createTextVNode(" Library Name ")]),
        _: 1
      }), _createVNode(_component_BaseInputText, {
        state: _unref(libraryForm).libraryName,
        "onUpdate:state": _cache[0] || (_cache[0] = $event => _unref(libraryForm).libraryName = $event),
        validation: _unref(v$).libraryName,
        "onUpdate:validation": _cache[1] || (_cache[1] = $event => _unref(v$).libraryName = $event),
        class: "mt-2",
        name: "library-name",
        placeholder: "Library name...",
        onKeypress: _cache[2] || (_cache[2] = _withKeys($event => _unref(onboardingFooter) ? _unref(onboardingFooter)?.nextClick() : null, ["enter"]))
      }, null, 8, ["state", "validation"])]), _createVNode(_component_OnboardingFooter, {
        ref_key: "onboardingFooter",
        ref: onboardingFooter,
        "next-to": _unref(nextTo),
        "previous-to": _unref(previousTo),
        "next-enabled": !_unref(v$).libraryName.$invalid && _unref(libraryForm).libraryName !== null
      }, null, 8, ["next-to", "previous-to", "next-enabled"])], 64);
    };
  }
};