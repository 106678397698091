import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue";

import { ref } from 'vue';
import { copyItemsToClipboard } from '@/utils';
const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
};
const _hoisted_2 = {
  key: 1,
  class: "flex items-center"
};
export default {
  __name: 'TableCopyButton',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    format: {
      type: String,
      default: "raw"
    }
  },
  setup(__props) {
    const props = __props;
    const itemsCopied = ref(false);
    function copy() {
      copyItemsToClipboard(props.items, props.format);
      itemsCopied.value = true;
      setTimeout(() => {
        itemsCopied.value = false;
      }, 2000);
    }
    return (_ctx, _cache) => {
      const _component_hero_clipboard_document_check = _resolveComponent("hero-clipboard-document-check");
      const _component_hero_clipboard_document = _resolveComponent("hero-clipboard-document");
      const _component_ButtonDefault = _resolveComponent("ButtonDefault");
      return _openBlock(), _createBlock(_component_ButtonDefault, {
        size: "sm",
        onClick: copy
      }, {
        default: _withCtx(() => [_unref(itemsCopied) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createTextVNode(" Copied!    "), _createVNode(_component_hero_clipboard_document_check, {
          class: "ml-2 w-4 h-4 text-green-500"
        })])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode("Copy selected")]), _createVNode(_component_hero_clipboard_document, {
          class: "ml-2 w-4 h-4"
        })]))]),
        _: 3
      });
    };
  }
};