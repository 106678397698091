import { api } from "@/api"

export async function getGscProperties() {
	const res = await api.get("/api/me/gsc-sites")
	if (res?.status >= 300) {
		return []
	} else if (res?.status < 300 && res.data?.length > 0 ) {
		return res.data.sort((a, b) => (a.site_url > b.site_url ? 1 : -1))
	} else {
		return []
	}
}