import { api } from "@/api"
import dayjs from "dayjs"

export async function getLibraryGscWithRegex(libraryId, options) {
	const body = {
		"startDate": options.startDate || dayjs().subtract(31, "day").format("YYYY-MM-DD"),
		"endDate": options.endDate || dayjs().subtract(1, "day").format("YYYY-MM-DD"),
		"rowLimit": options.rowLimit || 10,
		"dimensions": options.dimensions || "QUERY",
		"dimensionFilterGroups": [
			{
				"groupType": "AND",
				"filters": [
					{
						"dimension": options.regexDimension || "QUERY",
						"operator": options.regexType || "INCLUDING_REGEX",
						"expression": options.regex,
					},
				],
			},
		],
	}
	const res = await api.post(`api/libraries/${libraryId}/gsc`, body)
	return res?.data?.rows
}