import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";

import { useRoute, useRouter } from 'vue-router';
import { useLibraryFormStore } from '@/stores';
import { ref, computed, onMounted } from 'vue';
const _hoisted_1 = {
  key: 0,
  class: "text-stone-800"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
export default {
  __name: 'WorkspaceCreateLibraryReview',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const libraryForm = useLibraryFormStore();
    const previousTo = ref({
      name: "WorkspaceCreateLibraryFilters",
      params: {
        workspaceId: route.params.workspaceId
      }
    });
    async function beforeNext() {
      const libraryId = libraryForm.id;
      const workspaceId = route.params.workspaceId;
      const res = await libraryForm.publishLibrary(workspaceId, libraryId);
      if (res) {
        router.push({
          name: "LibraryOverview",
          params: {
            workspaceId,
            libraryId
          }
        });
      }
    }
    const currentItem = ref(0);
    const delayed = ref(false);
    const loadingComplete = computed(() => libraryForm.filteredLibraryPreviewResponse && delayed.value);
    const loadingSuccess = computed(() => libraryPreviewStatus.value && !libraryPreviewStatus.value.error);
    const libraryPreviewStatus = computed(() => {
      if (loadingComplete.value && libraryForm.filteredLibraryPreviewResponse.page_count < 10) {
        return {
          text: 'Library size is < 10 pages.',
          error: 'WARN'
        };
      } else if (loadingComplete.value && libraryForm.filteredLibraryPreviewResponse.page_count > 20000) {
        return {
          text: 'Library size is > 20,000 pages.',
          error: 'WARN'
        };
      } else {
        return {
          text: 'Estimating library size'
        };
      }
    });
    const items = computed(() => {
      return [{
        text: 'Adding library filters'
      }, {
        text: 'Previewing library pages and recommendations'
      }, libraryPreviewStatus.value];
    });
    onMounted(() => {
      setTimeout(function () {
        currentItem.value = 1;
      }, 1700);
      setTimeout(function () {
        currentItem.value = 2;
      }, 2900);
      setTimeout(function () {
        delayed.value = true;
      }, 2000);
    });
    return (_ctx, _cache) => {
      const _component_OnboardingHeader = _resolveComponent("OnboardingHeader");
      const _component_LoadingList = _resolveComponent("LoadingList");
      const _component_OnboardingFooter = _resolveComponent("OnboardingFooter");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_OnboardingHeader, {
        "current-num": "6",
        "total-num": "6"
      }, {
        title: _withCtx(() => [_createTextVNode(" Review & publish ")]),
        description: _withCtx(() => [_createTextVNode(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ac faucibus elit, a tincidunt purus. Cras varius quam tempor. ")]),
        _: 1
      }), _createVNode(_component_LoadingList, {
        class: "shadow-inner mb-8 mt-4",
        items: _unref(items),
        "current-item": _unref(currentItem),
        complete: _unref(loadingComplete)
      }, null, 8, ["items", "current-item", "complete"]), _unref(loadingComplete) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_unref(loadingSuccess) ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Everything looks good, time to publish your library!")) : (_openBlock(), _createElementBlock("p", _hoisted_3, "You may want to adjust your library settings before publishing."))])) : _createCommentVNode("", true), _createVNode(_component_OnboardingFooter, {
        "next-to": {},
        "previous-to": _unref(previousTo),
        "next-enabled": _unref(loadingComplete),
        "before-next": beforeNext
      }, {
        next: _withCtx(() => [_createTextVNode("Publish")]),
        _: 1
      }, 8, ["previous-to", "next-enabled"])], 64);
    };
  }
};