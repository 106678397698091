import Papa from "papaparse"
import { round } from "@/utils"

export function copyItemsToClipboard(items, config = "raw") {
	if (config === "raw") {
		copyRaw(items)
	} else {
		copyFormatted(items, config)
	}
}

export function copyDataToClipboard(data) {
	if (typeof data === "object") {
		copyRaw(data)
	} else {
		navigator.clipboard.writeText(data)
	}
}

function copyRaw(items) {
	const papaConfig = { delimiter: "\t", newline: "\n"}
	const string = Papa.unparse(items, papaConfig)
	navigator.clipboard.writeText(string)
}

function copyFormatted(items, config) {
	let clipString = ""
	config = typeof config == "string" ? presets[config] : config

	config.forEach(header => {
		clipString += `${header.display}\t`
	})
	clipString += "\n"

	items.forEach(item => {
		config.forEach(header => {
			if (header.dataPoint == "collections") {
				clipString += getCollectionString(item) + "\t"
			} else if (header.dataPoint == "flags") {
				clipString += getFlagString(item) + "\t"
			} else {
				let a = item
				header.dataPoint.split(".").forEach(prop => { 
					a = a !== undefined && a[prop] !== undefined ? a[prop] : undefined 
				})
				a = typeof a == "number" ? round(a) : a 
				clipString += a == undefined ? "\t" : a + "\t"
			}
		})
		clipString += "\n"
	})

	navigator.clipboard.writeText(clipString)
}

function getCollectionString(page) {
	let collectionString = ""
	if (page?.collections) {
		if (page.collections.isStar) collectionString += "⭐️Star,  "
		if (page.collections.isDeclining) collectionString += "📉Declining,  "
		if (page.collections.isSloth) collectionString += "🦥Sloth,  "
		if (page.collections.isWallflower) collectionString += "🌼Wallflower,  "
		if (page.collections.isLemon) collectionString += "🍋Lemon,  "
		if (page.collections.isNewGrowth) collectionString += "🎉New,  " 
		collectionString = collectionString.slice(0, -3)
	}
	return collectionString  
}

function getFlagString(page) {
	let flagString = ""
	if (page?.flags) {
		if (page.flags.updateContent) flagString += "Rewrite Content,  "
		if (page.flags.increaseEngagement) flagString += "Improve Engagement,  "
		if (page.flags.repurpose) flagString += "Repurpose,  "
		if (page.flags.titleIsMissing) flagString += "Fix Missing Title,  "
		if (page.flags.titleLengthIncorrect) flagString += "Shorten Title,  "
		flagString = flagString.slice(0, -3)
	}
	return flagString
}

const presets = {
	pages: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Age (days)", dataPoint: "daysAlive" },
		{ display: "1 Mo. Views", dataPoint: "org1Month.pageviews" },
		{ display: "1 Mo. Bounce Rate", dataPoint: "org1Month.bounceRate" },
		{ display: "1 Mo. % Change", dataPoint: "org1Month.growthPercent" },
		{ display: "3 Mo. Views", dataPoint: "org3Month.pageviews" },
		{ display: "3 Mo. Bounce Rate", dataPoint: "org3Month.bounceRate" },
		{ display: "3 Mo. % Change", dataPoint: "org3Month.growthPercent" },
		{ display: "Page Score", dataPoint: "calcs.score" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	ottimoExporterPages: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "ID", dataPoint: "id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Age (days)", dataPoint: "daysAlive" },
		{ display: "1 Mo. Views", dataPoint: "org1Month.pageviews" },
		{ display: "1 Mo. Bounce Rate", dataPoint: "org1Month.bounceRate" },
		{ display: "1 Mo. % Change", dataPoint: "org1Month.growthPercent" },
		{ display: "3 Mo. Views", dataPoint: "org3Month.pageviews" },
		{ display: "3 Mo. Bounce Rate", dataPoint: "org3Month.bounceRate" },
		{ display: "3 Mo. % Change", dataPoint: "org3Month.growthPercent" },
		{ display: "Page Score", dataPoint: "calcs.score" },
		{ display: "Opportunity", dataPoint: "calcs.opportunityGrowthMetric" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	ottimoExporterTopics: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "ID", dataPoint: "id" },
		{ display: "Topic", dataPoint: "searchData.topic" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Clicks", dataPoint: "searchData.clicks" },
		{ display: "Impressions", dataPoint: "searchData.impressions" },
		{ display: "Keyword Count", dataPoint: "searchData.keywordCount" },
		{ display: "Topic Match %", dataPoint: "matchScore" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	topicExplorerKeywords: [
		{ display: "Keyword", dataPoint: "keyword"},
		{ display: "Clicks", dataPoint: "clicks"},
		{ display: "Impressions", dataPoint: "impressions"},
		{ display: "Volume", dataPoint: "volume"},
		{ display: "Competition", dataPoint: "competition"},
		{ display: "Rank", dataPoint: "position"},
		{ display: "Top Ranking Page", dataPoint: "topPage.canonical_page_id"},
	],
	topicExplorerPages: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "Topic", dataPoint: "searchData.topic" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Clicks", dataPoint: "searchData.clicks" },
		{ display: "Impressions", dataPoint: "searchData.impressions" },
		{ display: "Keyword Count", dataPoint: "searchData.keywordCount" },
		{ display: "Topic Match %", dataPoint: "matchScore" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	topicReportKeywords: [
		{ display: "Keyword", dataPoint: "keyword"},
		{ display: "Clicks", dataPoint: "clicks"},
		{ display: "Impressions", dataPoint: "impressions"},
		{ display: "Volume", dataPoint: "volume"},
		{ display: "Rank", dataPoint: "position"},
	],
	actionItems: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Age (days)", dataPoint: "daysAlive" },
		{ display: "1 Mo. Views", dataPoint: "org1Month.pageviews" },
		{ display: "1 Mo. Bounce Rate", dataPoint: "org1Month.bounceRate" },
		{ display: "1 Mo. % Change", dataPoint: "org1Month.growthPercent" },
		{ display: "Page Score", dataPoint: "calcs.score" },
		{ display: "Opportunity", dataPoint: "calcs.opportunityGrowthMetric" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
}