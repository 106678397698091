import { renderSlot as _renderSlot, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";

import { useUserStore } from '@/stores';
import { onMounted } from 'vue';
const _hoisted_1 = {
  class: "w-full h-full flex fixed inset-0 bg-white z-50"
};
const _hoisted_2 = {
  class: "h-full relative overflow-scroll w-full px-8 py-24"
};
const _hoisted_3 = {
  class: "w-full h-full min-h-[600px] max-w-[650px] px-8 mx-auto flex flex-col relative"
};
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center mx-auto fixed md:top-[20px] px-8 md:right-0"
};
export default {
  __name: 'OnboardingLayout',
  setup(__props) {
    const userStore = useUserStore();
    onMounted(async () => {
      userStore.populateGaProperties();
      userStore.populateGscProperties();
      userStore.getWorkspaceLibraries();
    });
    return (_ctx, _cache) => {
      const _component_ButtonDefault = _resolveComponent("ButtonDefault");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default"), _unref(userStore).fallbackLibrary ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_ButtonDefault, {
        to: {
          name: 'LibraryOverview',
          params: {
            libraryId: _unref(userStore)?.fallbackLibrary?.id,
            workspaceId: _unref(userStore)?.fallbackLibrary?.workspace
          }
        },
        size: "md"
      }, {
        default: _withCtx(() => [_createTextVNode(" Cancel ")]),
        _: 1
      }, 8, ["to"])])) : _createCommentVNode("", true)])])]);
    };
  }
};