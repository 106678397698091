import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'CellCaret',
  props: {
    item: Object,
    link: String,
    target: {
      type: String,
      default: "_self"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_hero_chevron_right_16_solid = _resolveComponent("hero-chevron-right-16-solid");
      const _component_CellBase = _resolveComponent("CellBase");
      return _openBlock(), _createBlock(_component_CellBase, {
        item: __props.item,
        link: __props.link,
        target: __props.target
      }, {
        default: _withCtx(() => [_createVNode(_component_hero_chevron_right_16_solid, {
          class: "w-6 h-6 text-gray-500"
        })]),
        _: 1
      }, 8, ["item", "link", "target"]);
    };
  }
};