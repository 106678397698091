import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { numDisplay } from "@/utils";
import { Line } from "vue-chartjs";
import Chart from "chart.js/auto"; // eslint-disable-line no-unused-vars
import ChartDataLabels from "chartjs-plugin-datalabels";

import { computed } from 'vue';
export default {
  __name: 'LineChart',
  props: {
    chartData: Object,
    chartOptions: {
      type: [Object, String],
      default: "style1"
    },
    chartId: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    Chart.register(ChartDataLabels);
    const props = __props;
    const options = computed(() => {
      if (typeof props.chartOptions == "string") {
        return styleMap[props.chartOptions];
      } else if (typeof props.chartOptions == "object") {
        return props.chartOptions;
      } else {
        return undefined;
      }
    });
    const defaultStyleStarter = {
      animation: {
        duration: 200
      },
      interaction: {
        intersect: false,
        axis: "x",
        mode: "nearest"
      },
      pointRadius: 0,
      pointHitRadius: 20,
      maintainAspectRatio: false
    };
    const toolTipStylePrimary = {
      interaction: {
        mode: "index"
      },
      caretSize: 0,
      padding: 16,
      displayColors: false,
      borderColor: "#F3F4F6",
      borderWidth: 1,
      backgroundColor: "#fff",
      titleColor: "#6B7280",
      titleFont: {
        family: "'Ottimo Inter', Helvetica, sans-serif"
      },
      bodyColor: "#111827",
      bodyFont: {
        family: "'Ottimo Inter', Helvetica, sans-serif",
        weight: "bold"
      },
      titleMarginBottom: 15,
      bodySpacing: 8
    };
    const styleMap = {
      "style1": {
        ...defaultStyleStarter,
        fill: true,
        plugins: {
          tooltip: toolTipStylePrimary,
          legend: {
            display: false
          },
          datalabels: {
            display: false
          }
        },
        scales: {
          y: {
            min: 0,
            border: {
              dash: [3, 3]
            },
            ticks: {
              min: 0,
              maxTicksLimit: 5,
              callback: function (label) {
                return new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 1,
                  notation: "compact",
                  compactDisplay: "short"
                }).format(label);
              }
            }
          },
          x: {
            grid: {
              display: false
            }
          }
        }
      },
      "style2": {
        ...defaultStyleStarter,
        cubicInterpolationMode: "monotone",
        layout: {
          padding: {
            top: 10,
            left: 14,
            right: 14
          }
        },
        tension: 0.7,
        pointRadius: 1,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            align: "top",
            formatter: function (value) {
              return numDisplay(value, "m");
            },
            color: function (context) {
              return context.dataset.borderColor;
            }
          },
          tooltip: toolTipStylePrimary
        },
        scales: {
          y: {
            grace: "20%",
            ticks: {
              display: false,
              count: 5
            },
            min: 0,
            grid: {
              drawTicks: false,
              color: "#e5e7eb"
            }
          },
          x: {
            ticks: {
              padding: 10,
              display: false,
              font: {
                family: "'Ottimo Inter', Helvetica, sans-serif",
                size: 10
              }
            },
            grid: {
              drawTicks: false,
              color: "#e5e7eb"
            }
          }
        }
      }
    };
    return (_ctx, _cache) => {
      return __props.chartData && _unref(options) ? (_openBlock(), _createBlock(_unref(Line), {
        key: 0,
        id: __props.chartId,
        options: _unref(options),
        data: __props.chartData
      }, null, 8, ["id", "options", "data"])) : _createCommentVNode("", true);
    };
  }
};