export class TableConfig {
	constructor(options) {
		this.syncing = false
		this.by = options.by
		this.sortGroup = options.sortGroup || "org_last_3_months_data"
		this.dir = options.dir || "DESC"
		this.key = options.key || "id"
		this.pageNumber = options.pageNumber || 1
		this.pageSize = options.pageSize || 100
		this.totalItems = undefined
		this.searchText = options.searchText
		this.searchField = options.searchField || "page_text"
		this.checkboxes = options.checkboxes || false
		this.checked = {}
	}

	setSort(type){
		if (type == this.by) {
			if (this.dir == "ASC") this.dir = "DESC"
			else if (this.dir == "DESC") this.dir = "ASC"
		} else {
			this.by = type
			this.dir = "DESC"
		}

		this.pageNumber = 1
	}

	setPage(pn) {
		this.pageNumber = pn
	}

	setSearch(searchText) {
		this.searchText = searchText
	}

	get() {
		return {
			pageSize: this.pageSize, 
			pageNumber: this.pageNumber, 
			searchText: this.searchText,
			sortGroup: this.sortGroup,
			sortDirection: this.dir,
			sortField: "page_views",
		}
	}

	getCheckedItems() {
		return Object.keys(this.checked).map(key => this.checked[key])
	}

	toggleCheck(item) {
		if (this.checked[item[this.key]]) {
			delete this.checked[item[this.key]]
		} else {
			this.checked[item[this.key]] = item
		}
	}

	toggleAllChecks(items) {
		if (this.allChecked(items)) {
			items.forEach(item => {
				delete this.checked[item[this.key]]
			})
		} else {
			items.forEach(item => {
				this.checked[item[this.key]] = item
			})
		}
	}

	clearAllChecks() {
		this.checked = {}
	}

	isChecked(item) {
		if (!item) return
			
		return !!this.checked[item[this.key]]
	}

	allChecked(items) {
		if (!items?.length > 0) return
			
		let anyUnchecked = false
		items.every(item => {
			if (!this.checked[item[this.key]]) {
				anyUnchecked = true
				return false
			} else {
				return true
			}
		})
		return !anyUnchecked
	}

	localFetch(items) {
		if (!items || items?.length == 0) return []
		let itemsToProcess = items

		// filter by search term	
		if (this.searchText && this.searchField && this.searchText != "") {
			const searchProps = this.searchField.split(".")

			itemsToProcess = itemsToProcess.filter(item =>{
				searchProps.forEach(prop => {
					item = item[prop] !== undefined ? item[prop] : undefined
				})

				if (item && item.length > 0) {
					return item.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
				} else {
					return false
				}
			})
		}

		// sort by property
		const properties = this.by.split(".")
		itemsToProcess = itemsToProcess.toSorted((a,b)=>{
			properties.forEach(prop => {
				a = a !== undefined ? a[prop] : undefined
				b = b !== undefined ? b[prop] : undefined
			})

			if (typeof a == "string" && typeof b == "string") {
				if (this.dir == "DESC") {
					return a.toLowerCase() > b.toLowerCase() ? 1 : -1
				} else {
					return a.toLowerCase() > b.toLowerCase() ? -1 : 1
				}
			}

			if (this.dir == "DESC") {
				return a > b ? -1 : 1
			} else {
				return a > b ? 1 : -1
			}
		})

		this.totalItems = itemsToProcess?.length

		// return paginated items
		const sliceStart = (this.pageNumber - 1) * this.pageSize
		return itemsToProcess.slice(sliceStart, sliceStart + this.pageSize)
	}

}