import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
const _hoisted_1 = ["href"];
export default {
  __name: 'BubbleBase',
  props: {
    to: Object,
    href: String,
    size: {
      type: String,
      default: "md"
    }
  },
  setup(__props) {
    const props = __props;
    const classString = computed(() => {
      let str = "block select-none flex items-center justify-center bg-gray-100 rounded-full text-gray-800 flex-none ";
      if (props.size == "sm") str += "text-xs px-3 h-[22px]";else if (props.size == "md") str += "text-sm h-[25px] px-3";else if (props.size == "lg") str += "text-base h-[32px] px-4";
      return str;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return __props.to ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: __props.to,
        class: _normalizeClass([_unref(classString), "hover:bg-gray-200 select-none"])
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["to", "class"])) : __props.href ? (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: __props.href,
        target: "_blank",
        class: _normalizeClass([_unref(classString), "hover:bg-gray-200"])
      }, [_renderSlot(_ctx.$slots, "default")], 10, _hoisted_1)) : (_openBlock(), _createElementBlock("span", {
        key: 2,
        class: _normalizeClass(_unref(classString))
      }, [_renderSlot(_ctx.$slots, "default")], 2));
    };
  }
};