import { herokuApi } from "@/api"
import Papa from "papaparse"

export async function getKeywordData(keyword) {
	const res = await herokuApi.get("/semrush-single-keyword-overview/" + encodeURIComponent(keyword))
	if (res?.data?.results && res.data.results.indexOf("ERROR 50") == -1 ) {
		const { data } = Papa.parse(res.data.results, { header: true, dynamicTyping: true })
		return data.map(row => {
			return {
				keyword: row["Keyword"],
				volume: row["Search Volume"],
				competition: row["Keyword Difficulty Index"]
			}
		})
	} else {
		return []
	}
}