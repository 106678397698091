import { api } from "@/api"
import { processPages, scorePages } from "@/utils"

export async function getLibraryPages(libraryId, options) {
	const res = await api.get(`/api/libraries/${libraryId}/pages`, {
		params: { 
			ps: options.pageSize || 10, 
			pn: options.pageNumber || 1,
			page_text: options.searchText ? options.searchText.replaceAll("-", " ").replaceAll("/", " ") : undefined,
			so_group: options.sortGroup || "org_last_3_months_data",
			so_field: options.sortField || "page_views",
			so_direction: options.sortDirection || "DESC"
		}
	})

	if (res?.data?.results) {
		const count = res.data?.count || 0
		const processedPages = processPages(res.data.results)
		const pages = scorePages(processedPages)
		console.log(pages)
		return { count, pages }
	} else {
		return
	}
}

