import { listWorkspaceLibraries } from "@/api/workspace";
export const authCheck = async (to, from) => {
  if (process.env.VUE_APP_MAINTENANCE_MODE == "TRUE") return true;

  // allow the page to pass if only query parameters have changed
  if (to.name === from.name) return true;
  const userStore = useUserStore();
  await userStore.authCheck();
  if (to.name === "Home") {
    if (userStore.user?.workspaces?.length > 0) {
      // If the user already has a workspace, redirect to the appropriate workspace homepage
      return await findWorkspaceHome(userStore.user.workspaces[0].id);
    } else if (userStore.user?.invitations?.length > 0) {
      // If a user has no workspace, but open invitations, show the invitations page
      return {
        name: "AppOpenInvitations"
      };
    } else if (userStore.user) {
      // Otherwise if a user is logged in, show them the workspace creation page
      return {
        name: "WorkspaceCreate"
      };
    } else {
      // If they are not logged in, send them to the login page
      return {
        name: "AppLogin"
      };
    }
  }
  if (to.name === "WorkspaceHome" && userStore.user && to.params.workspaceId) {
    if (userStore.user?.workspaces?.map(ws => ws.id).includes(parseInt(to.params.workspaceId))) {
      return await findWorkspaceHome(to.params.workspaceId);
    } else {
      return {
        name: "AppOpenInvitations"
      };
    }
  }

  // no auth required
  if (!to.meta.authRequired) return true;

  // if auth is required and user isn't logged in
  if (to.meta.authRequired && !userStore.user) return "/login";
  if (to.name === "AppOpenInvitations" || to.name === "WorkspaceCreate") return true;

  // if no workspaces are created yet, jump to the beginning of onboarding
  if (!userStore.user?.workspaces || userStore.user?.workspaces?.length == 0) return "/open-invitations";
  return true;
};

// redirect a workspace ID to either the first available library, or the library creation page for the workspace
async function findWorkspaceHome(workspaceId) {
  // this needs to handle looking through multiple workspaces, or else will throw to a library creation screen even if user has libraries in an alternate workspace
  const libraries = await listWorkspaceLibraries(workspaceId);
  if (libraries && libraries?.length > 0) {
    return {
      name: "LibraryOverview",
      params: {
        workspaceId,
        libraryId: libraries[0].id
      }
    };
  } else {
    return {
      name: "WorkspaceCreateLibrary",
      params: {
        workspaceId
      }
    };
  }
}